import React from 'react'

const FormStep2 = (props) => {
    const {inputValues, fieldErrors} = props

    return (
        <div id="formStep2">
            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Describe your program offering and how you are committed to developing talent for the Salesforce Ecosystem
                            </div>
                            <tds-input id="Landing_Page_Description__c" type="text" required="true" placeholder="Your answer"></tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Your program curriculum includes or will include at least 10 Trailhead Modules
                            </div>
                            <tds-radio-group 
                                id="Is_Include_Program_Curriculum__c"
                                required="true"
                                value={inputValues.Is_Include_Program_Curriculum__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Do you already have Salesforce Certified staff who will be supporting training?
                            </div>
                            <tds-combobox 
                                id="Certified_for_Training_Support__c" 
                                required="true" 
                                placeholder="Select one..."></tds-combobox>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                This question does not have bearing on your acceptance into the program 
                                but helps Salesforce understand where we can support our Workforce Partners.
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Do you already have a developed training program using Salesforce content?
                            </div>
                            <tds-combobox 
                                id="Developed_Training_using_Salesforce__c" 
                                required="true" 
                                placeholder="Select one..."></tds-combobox>
                        </div>

                        {
                        /*
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                If available, please provide a link to your curriculum including your Trailhead Content.
                            </div>
                            <tds-input 
                                id="Link_to_Curriculum__c" 
                                type="text" 
                                placeholder="URL" 
                                data-validate="url"
                                value={inputValues['Link_to_Curriculum__c']}
                                error-message={fieldErrors['Link_to_Curriculum__c']}></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Links to a public curriculum outline or a private shared link (GDrive, Dropbox, etc.).
                            </div>
                        </div>
                        */
                        }
                    </div>
                </tds-card>
            </div>

            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                What do you hope to accomplish by joining the Workforce Partner Program?
                            </div>
                            <tds-input id="Accomplishments_by_Workforce_Program__c" type="text" required="true" placeholder="Your answer"></tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                What costs are learners expected to cover?
                            </div>
                            <tds-input id="Cost_To_Participant__c" type="text" required="true" placeholder="Your answer"></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Please include any information on financial costs or other resources required for the learner.
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Does your program deliver instructor-led training?
                            </div>
                            <tds-radio-group 
                                id="Instructorled_Training_Provided__c"
                                required="true"
                                value={inputValues.Instructorled_Training_Provided__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Does your program include Salesforce certification or credentialing?
                            </div>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                We provide a limited number of vouchers to support certifications
                            </div>
                            <tds-radio-group
                                id="Program_include_Certification__c" 
                                required="true"
                                value={inputValues.Program_include_Certification__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Does your program include industry mentorship?
                            </div>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                We match participants with an industry mentor during the program.
                            </div>
                            <tds-radio-group 
                                id="Is_Mentorship_Included__c"
                                required="true"
                                value={inputValues.Is_Mentorship_Included__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Does your program include a case study?
                            </div>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                We use case studies or case competitions where participants build solutions during the program.
                            </div>
                            <tds-radio-group 
                                id="Is_Case_Study__c"
                                required="true"
                                value={inputValues.Is_Case_Study__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Does your program include experience projects?
                            </div>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                We have participants build real-world solutions with partners during our programs.
                            </div>
                            <tds-radio-group 
                                id="Is_Include_Experience_Project__c"
                                required="true"
                                value={inputValues.Is_Include_Experience_Project__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Does your program provide placement support to participants?
                            </div>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                We provide career fairs, individual career placement, or employer partners available to our participants.
                            </div>
                            <tds-radio-group 
                                id="Is_Placement_Support__c"
                                required="true"
                                value={inputValues.Is_Placement_Support__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Does your program include career coaching?
                            </div>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                We provide career coaching, career panels, and other career resources for participants.
                            </div>
                            <tds-radio-group 
                                id="Is_Career_Coaching__c"
                                required="true"
                                value={inputValues.Is_Career_Coaching__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>
                    </div>
                </tds-card>
            </div>

            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <tds-input 
                                id="Provide_Periodic_reports_to_Salesforce__c"
                                label="Your organization agrees to provide periodic reports to Salesforce as part of the Workforce Partner Program"
                                type="checkbox"
                                required="true"></tds-input>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                Salesforce will send report templates that are brief and contain metrics associated with recruitment, 
                                graduation/completion rates, certifications, placements, mentorship, etc. 
                                Reports are used to track impact and allows Salesforce to understand the needs of our Workforce Partners helping to allocate support, 
                                funding, program development resources and marketing amplification where needed.
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                How many learners did you serve in the last year (estimate, if applicable)?
                            </div>
                            <tds-input 
                                id="Leaner_Served_in_Last_Year__c" 
                                type="text" 
                                data-validate="integer" 
                                placeholder="Your answer"
                                value={inputValues['Leaner_Served_in_Last_Year__c']}
                                error-message={fieldErrors['Leaner_Served_in_Last_Year__c']}>
                            </tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                            How many graduates on average did you have in the last year (estimate, if applicable)?
                            </div>
                            <tds-input 
                                id="Graduates_Count_of_Last_Year__c" 
                                type="text" 
                                data-validate="integer"
                                placeholder="Your answer"
                                value={inputValues['Graduates_Count_of_Last_Year__c']}
                                error-message={fieldErrors['Graduates_Count_of_Last_Year__c']}>
                            </tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">  
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Schedule reports by selecting the months that align with your program timeline (choose 2 - 4 months)
                            </div>
                            <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                We understand fiscal years and program models differ. 
                                Tell us which months work best for your organization and we can schedule reminders 
                                to be sent to you at the beginning of that month. 
                                Example: select months that align with program graduation, certification and/or placement timing.
                            </div>
                            <input type="hidden" id="Survey_Month__c" required="required" />

                            <div className="slds-container_small">
                                <div className="slds-grid slds-wrap">
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_January" type="checkbox" label="January" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_February" type="checkbox" label="February" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_March" type="checkbox" label="March" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_April" type="checkbox" label="April" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_May" type="checkbox" label="May" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_June" type="checkbox" label="June" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_July" type="checkbox" label="July" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_August" type="checkbox" label="August" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_September" type="checkbox" label="September" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_October" type="checkbox" label="October" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_November" type="checkbox" label="November" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                    <div className="slds-size_1-of-2 slds-medium-size_1-of-3">
                                        <tds-input id="Survey_Month__c_December" type="checkbox" label="December" data-for="Survey_Month__c"></tds-input>
                                    </div>
                                </div>
                            </div>

                            {fieldErrors['Survey_Month__c'] && (
                                <div className="u-field-error-message">{fieldErrors['Survey_Month__c']}</div>
                            )}
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Does your program serve an audience of at least 60% economically underserved relative to your geographic standards?
                            </div>
                            <tds-radio-group 
                                id="Atleast_60_economically_underserved__c"
                                value={inputValues.Atleast_60_economically_underserved__c}>
                                <div className="u-font-size-2 slds-p-bottom_x-small slds-text-color_weak">
                                    Salesforce is committed to support equality for all and to helping underrepresented groups enter the ecosystem. 
                                    This question does not have any bearing on your acceptance to the Workforce Partner Program.
                                </div>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Which of the following best describes the primary audience your program seeks to serve?
                            </div>
                            <tds-combobox 
                                id="Primary_Group_Served__c" 
                                placeholder="Select one..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Which of the following best describes the secondary audience your program seeks to serve?
                            </div>
                            <tds-combobox 
                                id="Secondary_Group_Served__c" 
                                placeholder="Select one..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Does your organization track demographic data for participants?
                            </div>
                            <tds-radio-group 
                                id="Track_demographic_data_for_participants__c"
                                value={inputValues.Track_demographic_data_for_participants__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Is there anything else you'd like to share about the audience your program seeks to serve?
                            </div>
                            <tds-input id="Group_Served_Details__c" type="text" placeholder="Your answer"></tds-input>
                        </div>
                    </div>
                </tds-card>
            </div>

            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Are you looking for volunteers?
                            </div>
                            <tds-combobox 
                                id="Volunteers__c" 
                                placeholder="Select one..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Please share a landing page where volunteering opportunities are made available (if it exists).
                            </div>
                            <tds-input 
                                id="Volunteer_Url__c" 
                                type="text" 
                                placeholder="URL" 
                                data-validate="url"
                                value={inputValues['Volunteer_Url__c']}
                                error-message={fieldErrors['Volunteer_Url__c']}></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Make sure the URL includes the protocol like: "http://" or "https://"
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Are you interested in co-teaching or creating an experience-based learning model with a Salesforce consulting, 
                                ISV, digital agency, or reseller partner?
                            </div>
                            <tds-radio-group 
                                id="Partnering_consulting__c"
                                value={inputValues.Partnering_consulting__c}>
                                <tds-input type="radio" label="I already work with a Salesforce partner" value="I already work with a Salesforce partner"></tds-input>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>
                    </div>
                </tds-card>
            </div>
        </div>
    )

}

export default FormStep2