import React, { useContext } from 'react'
import I18n, {LangContext} from '../utils/I18n'

const FormIntro = (props) => {
    const {handleNext, formActive} = props
    const lang = useContext(LangContext)

    return (
        <div id="formStep0">
            <tm-hero-a 
                background="white" 
                title="Welcome to the Trailblazer Workforce Partner Program!" 
                img-src="https://developer.salesforce.com/resources2/wfp/program/workforce-program-hero.png" 
                img-alt="Trailblazer Workforce Partner Program hero image" />
            
            <div className="u-bg-white">
                <div className="slds-container_x-large slds-container_center slds-p-horizontal_small">
                    <p className="slds-p-bottom_medium">
                        Everyone deserves a pathway to jobs of the future. We want every learner to find the right program that fits their needs – based on location, 
                        format, cost, career path, and/or identity. 
                        We want to support and empower organizations, like you, by providing Trailhead resources, community, and connections.<br />
                        Complete this form to apply to be part of our Trailblazer Workforce Partner Program and be listed in our directory.
                    </p>
                    <p className="slds-p-bottom_medium">
                        <span>
                            Learn more about our Trailblazer Workforce Partner program, 
                            requirements and eligibility, and benefits
                        </span>
                        &nbsp;
                        <a target="_blank" rel="noreferrer" href={`/${lang}/trailblazerconnect/workforce-partners`}>here</a>.
                    </p>
                    <p className="slds-p-bottom_medium">
                        Questions about the program or this application? Check out our&nbsp;
                        <a target="_blank" rel="noreferrer" href="https://salesforce.quip.com/cXNKA5wxHFif">FAQ</a>.
                    </p>
                    <div className="slds-p-vertical_large slds-text-align_center">
                        {
                            /*formActive && (
                                <>
                                    <tds-button variant="brand" onClick={handleNext}>Apply</tds-button>
                                </>
                            ) || (
                                <>
                                    <tds-button variant="brand" disabled={true}>Apply</tds-button>
                                    <div className="slds-p-top_small">The site is being updated and will be back up shortly. Please check back soon.</div>
                                </>
                            )*/
                        }
                        
                        <tds-button variant="brand" onClick={handleNext}>Apply</tds-button>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default FormIntro