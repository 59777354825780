// using CommonJS export because function is used in gatsby-node.js file and runs during node build
module.exports = {
  getDropdownsOptions: () => {
    return {
      Type_of_Organization__c: [
          'Nonprofit, non-governmental, not-for-profit, or charitable organization',
          'Education institution, public school, technical training center or trade school',
          'Salesforce Community-led program or user group',
          'Workforce development or training business/company',
          'Salesforce implementation partner',
          'Other'
      ],
      Certified_for_Training_Support__c: [
          'No',
          'No - but we do have Trailhead Rangers on staff',
          'Yes - Certified Salesforce Professionals on staff',
          'Yes - fully Certified Instructors and Professionals on staff'
      ],
      Developed_Training_using_Salesforce__c: [
          'Yes - using Trailhead Trailmixes',
          'Yes - using new or self-developed curriculum',
          'Yes - using established or purchased curriculum',
          'No current training program or curriculum'
      ],
      Primary_Group_Served__c: [
          'Ethnic Minorities',
          'Extended Unemployed',
          'Incarcerated (Currently or Previously)',
          'LGBTQIA+',
          'Parents Returning to Work',
          'People with Disabilities',
          'Racial Minorities',
          'Religious Minorities',
          'Refugees',
          'Veterans',
          'Women',
          'No particular group'
      ],
      Secondary_Group_Served__c: [
          'Ethnic Minorities',
          'Extended Unemployed',
          'Incarcerated (Currently or Previously)',
          'LGBTQIA+',
          'Parents Returning to Work',
          'People with Disabilities',
          'Racial Minorities',
          'Religious Minorities',
          'Refugees',
          'Veterans',
          'Women',
          'No particular group'
      ],
      Volunteers__c: [
          'Yes - volunteers that volunteer on a regular basis (1-4x per month)',
          'Yes - volunteer instructors or experts',
          'Yes - occasional volunteers for specific events',
          'Not at this time',
          'NA'
      ],
      Program_Length__c: [
          'Less than 4 weeks',
          '1-3 Months',
          '4-6 Months',
          '7 Months - 1 Year',
          '1+ Year'
      ],
      Is_your_program_available_virtually__c: [
          'Fully virtual/remote',
          'Both virtual/remote and in-person',
          'Fully in-person'
      ],
      Program_State__c: [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
      ]
    }
  },
}
