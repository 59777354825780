import React from 'react'
import Layout from '../layouts/Layout'
import Form from '../components/Form'
import {LangContext} from '../utils/I18n'

const FormTemplate = data => {
  const {pageContext: {lang, formActive}} = data

  return (
    <LangContext.Provider value={lang}>
      <Layout>
          <div>
            <tm-page>
                <Form formActive={formActive} />
              <div>
                <th-element-loader element="tm-page" style={{height: '100vh'}} />
              </div>
            </tm-page>
          </div>
      </Layout>
    </LangContext.Provider>
  )
}

export default FormTemplate
