import React from 'react'
import ReCAPTCHA from "react-google-recaptcha"

const FormStep4 = (props) => {
    const {recaptchaRef, setRecaptchaToken, handleRecaptchaFail, showOptIn, optInText} = props

    const handleRecaptcha = (value) => {
        setRecaptchaToken(value)
    }

    return (
        <div id="formStep4">
            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="slds-grid slds-wrap"> 
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="slds-m-bottom_small">
                                <span>Please read and review the following documents: </span>
                                <br />
                                <span className="slds-m-left_small">• Program Terms for Networking Portals, subject to the </span>
                                    <a href="https://www.salesforce.com/company/program-agreement/" target="_blank" rel="noreferrer">Salesforce Program Agreement</a>  
                                <br />
                                <span className="slds-m-left_small">• <a href="https://www.salesforce.com/company/privacy/" target="_blank" rel="noreferrer">Privacy Statement</a></span>
                                <br />
                                <span className="slds-m-left_small">• Branding Guidelines: </span>
                                    <a href="https://salesforce.quip.com/bVyLAak6XC0k" target="_blank" rel="noreferrer">Trailblazer Workforce Partner Branding Guidelines</a>
                            </div>

                            <div className="u-input-label slds-container_medium">
                                <span>By checking 'I Agree', you confirm that you are authorized to fill out this application to join the Trailblazer Workforce Partner Program and accept Salesforce’s Program Terms for Networking Portals, subject to the Salesforce Program Agreement, to the processing of your personal data by Salesforce as described in the Privacy Statement, and to adhere to the Branding Guidelines on behalf of your company.</span>
                            </div>

                            <tds-input 
                                id="Is_Term_Accepted__c"
                                label="I Agree"
                                type="checkbox"
                                required="true" />

                            <div style={{display: showOptIn ? 'block' : 'none'}}>
                                <tds-input 
                                    id="Opt_In_Check__c"
                                    label={optInText}
                                    type="checkbox" />
                            </div>
                            
                            <div className="slds-p-top_large">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                                    onChange={handleRecaptcha}
                                    onExpired={handleRecaptchaFail}
                                    onErrored={handleRecaptchaFail} />
                            </div>
                        </div>
                    </div>
                </tds-card>
            </div>
        </div>
    )

}

export default FormStep4