import React from 'react'

const FormStep3 = (props) => {
    const {inputValues, fieldErrors} = props

    return (
        <div id="formStep3">
            <div className="slds-container_large slds-container_center slds-p-bottom_large">
                <tds-card content-mode="padded">
                    <div className="slds-grid slds-wrap">
                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Organization name as you'd like it to appear on the directory site
                            </div>
                            <tds-input id="Organisation_Directory_Name__c" type="text" required="true" placeholder="Your answer"></tds-input>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="slds-form-element">
                                <div className="u-input-label">
                                    <span className="u-required-simbol">*</span> 
                                    Please upload your logo with a transparent background (max: 300kb)
                                </div>
                                <div className="slds-form-element__control">
                                    <div className="slds-file-selector1 slds-file-selector_files1">
                                        <div className="slds-file-selector__dropzone">
                                            <input 
                                                type="file" 
                                                className="slds-file-selector__input slds-assistive-text u-file-uploader" 
                                                accept="image/*" 
                                                id="Upload_Logo__c" 
                                                required="required"
                                                aria-labelledby="file-selector-primary-label-205 file-selector-secondary-label206" />
                                            <label className="slds-file-selector__body u-cursor-pointer" htmlFor="Upload_Logo__c" id="file-selector-secondary-label206">
                                                <div className="slds-file-selector__button slds-button slds-button_neutral1 u-button-text_neutral u-full-width">
                                                    <div className="slds-grid slds-grid_align-spread slds-grid_vertical-align-center u-full-width">
                                                        <div className="slds-col">
                                                            File Upload
                                                        </div>
                                                        <div className="slds-col">
                                                            <i className="icon-upload" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Please provide a sentence description of your program for recruitment purpose (150 - 180 characters)
                            </div>
                            <tds-input 
                                id="Description_for_Recruitment_Purpose__c" 
                                type="text" 
                                required="true" 
                                placeholder="Your answer"
                                data-validate="limit"
                                data-limit-max="180"
                                value={inputValues['Description_for_Recruitment_Purpose__c']}
                                error-message={fieldErrors['Description_for_Recruitment_Purpose__c']}></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Please use exact text and check for grammatical errors, this will be published upon approval.
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Please provide a sentence description of your program for employer outreach (150 - 180 characters)
                            </div>
                            <tds-input 
                                id="Description_for_Employer_Outreach__c" 
                                type="text" 
                                required="true"
                                data-validate="limit"
                                data-limit-max="180" 
                                placeholder="Your answer"
                                value={inputValues['Description_for_Employer_Outreach__c']}
                                error-message={fieldErrors['Description_for_Employer_Outreach__c']}></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Please use exact text and check for grammatical errors, this will be published upon approval.
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Is there a cost to enroll in your program?
                            </div>
                            <tds-radio-group 
                                id="Is_there_a_cost_to_Enroll__c"
                                value={inputValues.Is_there_a_cost_to_Enroll__c}>
                                <tds-input type="radio" label="Yes" value="Yes"></tds-input>
                                <tds-input type="radio" label="No" value="No"></tds-input>
                            </tds-radio-group>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                How long is your program?
                            </div>
                            <tds-combobox 
                                id="Program_Length__c" 
                                required="true" 
                                placeholder="Select one..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">  
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                What career path does your program prepare learners for? 
                            </div>
                            <input type="hidden" id="Program_Path__c" required="required" />
                            <div className="slds-container_small slds-grid">
                                <div className="slds-size_1-of-3">
                                    <tds-input id="Program_Path__c_Administrator" type="checkbox" label="Administrator" data-for="Program_Path__c"></tds-input>
                                    <tds-input id="Program_Path__c_Developer" type="checkbox" label="Developer" data-for="Program_Path__c"></tds-input>
                                    <tds-input id="Program_Path__c_Marketer" type="checkbox" label="Marketer" data-for="Program_Path__c"></tds-input>
                                </div>
                                <div className="slds-size_1-of-3">
                                    <tds-input id="Program_Path__c_BusinessAnalyst" type="checkbox" label="Business Analyst" data-for="Program_Path__c"></tds-input>
                                    <tds-input id="Program_Path__c_SalesOperations" type="checkbox" label="Sales Operations" data-for="Program_Path__c"></tds-input>
                                    <tds-input id="Program_Path__c_Sales" type="checkbox" label="Sales" data-for="Program_Path__c"></tds-input>
                                </div>
                                <div className="slds-size_1-of-3">
                                    <tds-input id="Program_Path__c_Service" type="checkbox" label="Service" data-for="Program_Path__c"></tds-input>
                                    <tds-input id="Program_Path__c_Consultant" type="checkbox" label="Consultant" data-for="Program_Path__c"></tds-input>
                                    <tds-input id="Program_Path__c_Architect" type="checkbox" label="Architect" data-for="Program_Path__c"></tds-input>
                                </div>
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                Is your program available through a virtual platform?
                            </div>
                            <tds-combobox 
                                id="Is_your_program_available_virtually__c" 
                                placeholder="Select one..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">  
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Which region is your program primarily focused in?
                            </div>
                            <input 
                                type="hidden" 
                                id="Program_Region__c" 
                                required="required"
                            />
                            <div className="slds-container_small slds-grid">
                                <div className="slds-size_1-of-3">
                                    <tds-input id="Program_Region__c_AMER" type="checkbox" label="North America" data-for="Program_Region__c"></tds-input>
                                    <tds-input id="Program_Region__c_LATAM" type="checkbox" label="Latin America" data-for="Program_Region__c"></tds-input>
                                    <tds-input id="Program_Region__c_EMEA1" type="checkbox" label="Europe" data-for="Program_Region__c"></tds-input>
                                   
                                </div>
                                <div className="slds-size_2-of-3">
                                    <tds-input id="Program_Region__c_EMEA2" type="checkbox" label="Middle-East, and Africa" data-for="Program_Region__c"></tds-input>
                                    <tds-input id="Program_Region__c_APAC" type="checkbox" label="Asia-Pacific" data-for="Program_Region__c"></tds-input>
                                </div>
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label"> 
                                <span className="u-required-simbol">*</span> 
                                What country is your program primarily based in?
                            </div>
                            <tds-combobox 
                                id="Program_Country__c"
                                data-list="country" 
                                required="true" 
                                placeholder="Select country..."></tds-combobox>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small" style={{display: inputValues.Program_Country__c === 'United States' ? 'block' : 'none'}}>
                            <div className="u-input-label">
                                What US state is your program primarily based in?
                            </div>
                            <tds-combobox 
                                id="Program_State__c" 
                                placeholder="Select state..."></tds-combobox>
                        </div>

                        

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                What state or province is your program primarily based in?
                            </div>
                            <tds-input id="Program_Province__c" type="text" placeholder="Your answer"></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Please use exact text and check for grammatical errors, this will be published upon approval.
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                What language(s) do you teach your program in?
                            </div>
                            <tds-input id="Languages_used_program_in__c" type="text" placeholder="Your answer"></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Please use exact text and check for grammatical errors, this will be published upon approval.
                            </div>
                        </div>

                        <div className="slds-size_1-of-1 slds-p-around_small">
                            <div className="u-input-label">
                                <span className="u-required-simbol">*</span> 
                                Please provide a link for job seekers to learn more about your program
                            </div>
                            <tds-input 
                                id="Program_Link__c" 
                                type="text" 
                                required="true"
                                placeholder="URL" 
                                data-validate="url"
                                value={inputValues['Program_Link__c']}
                                error-message={fieldErrors['Program_Link__c']}></tds-input>
                            <div className="u-font-size-2 slds-p-top_small slds-text-color_weak">
                                Make sure the URL includes the protocol like: "http://" or "https://"
                            </div>
                        </div>
                    </div>
                </tds-card>
            </div>
        </div>
    )

}

export default FormStep3