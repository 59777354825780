import React from 'react'
import '../assets/stylesheets/components/_formSuccess.scss';

const FormSuccess = (props) => {

    return (
        <div id="formStep0">
            <div className="formSuccess">
                <div className="slds-text-heading_large slds-text-align_center slds-m-bottom_large">
                    Thanks for your application!
                </div>
                <div className="slds-container_medium slds-container_center">
                    Thank you for applying for the Trailblazer Workforce Partner program!
                    We will be in touch soon with decisions on your application and next steps. In the meantime, please check out these <a href="/">resources</a>.<br />
                    <b>Have questions?</b> Get in touch at <a href="mailto:trailblazerconnect@salesforce.com">trailblazerconnect@salesforce.com</a>
                </div>
            </div>
        </div>
    )

}

export default FormSuccess